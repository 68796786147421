import React from 'react';
import { ErrorLabel, URLInput } from '../styles';
import { Checkbox } from 'components/admin2/ui/Checkbox';
import { useCTAEditorModalContext } from '../Context';
import { OpenLinkBehaviorData } from '../utils';
import { isUrl } from 'url-utils';
import { useDebouncedState } from 'hooks';

const OpenLinkInputs = () => {
  const { cta, onChangeBehavior } = useCTAEditorModalContext();
  const behavior = cta.behavior as OpenLinkBehaviorData;
  const [debouncedUrl, setUrl, url] = useDebouncedState(behavior.url, 500);

  React.useEffect(() => {
    setUrl(behavior.url);
  }, [behavior.url]);

  React.useEffect(() => {
    if (debouncedUrl !== behavior.url) {
      onChangeBehavior<typeof behavior>({
        ...behavior,
        url: debouncedUrl,
      });
    }
  }, [debouncedUrl, onChangeBehavior, behavior.url]);

  const handleChangeOpenInNewTab = (value: boolean) => {
    onChangeBehavior<typeof behavior>({
      ...behavior,
      openInNewTab: value,
    });
  };

  return (
    <>
      <URLInput
        prefixInputIcon="linkOutline"
        padding="0"
        labelKey="ADMIN_LOGO_LINK_LABEL"
        placeholderKey="ADMIN_LABEL_ENTER_A_URL"
        type="url"
        onChange={setUrl}
        value={url}
        valid={Boolean(url && isUrl(url))}
      />
      <ErrorLabel
        errorKey="LINK_ERROR_PLEASE_TRY_AGAIN"
        hidden={!url || isUrl(url)}
      />
      <Checkbox
        labelKey="OPEN_IN_NEW_TAB"
        onCheckedChange={handleChangeOpenInNewTab}
        checked={behavior.openInNewTab}
      />
    </>
  );
};

export default OpenLinkInputs;
