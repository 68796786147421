import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Link from 'components/ui/Link';
import EditLogoBar from 'components/admin-bridge/EditLogoBar';
import type { LogoData } from 'components/admin-bridge/EditLogoBar/EditLogoBar';
import { Image } from 'components/header/styles';
import { TargetTypes, NO_IMAGE } from 'services/admin';
import isEqual from 'lodash/isEqual';
import { LOGO } from 'injection-classes';
import { clearEditTarget, setPendingAdminChange, navigateToAdminBarAction, setIsEditingGateLogo, setEditTarget } from 'services/admin/actions';
import { onNavClick } from 'services/third-party-analytics';
import { isAdminViewingAccessTab as isAdminViewingAccessTabAction } from 'services/gate/selectors';
import { getPageId } from 'services/app/selectors';
import { DynamicTranslationType, useEndUserTranslation } from 'hooks/use-translation';
import {
  getEditTarget,
  isEditMode,
  getIsEditingGateLogo,
} from 'services/admin/selectors';
import { ActionKey } from 'services/admin/constants';
import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';
import { isUserAdmin } from 'services/auth';
import { css } from 'styled-components';

interface IHeaderImageProps {
  data: LogoData,
  localGateLogoData: LogoData,
  saveGateLogo?: (data: LogoData) => void,
}

export default function HeaderImage(props: IHeaderImageProps) {
  const dispatch = useDispatch();
  const { endUserT } = useEndUserTranslation();
  const pageId = useSelector(getPageId);

  const [logoData, setLogoData] = useState<typeof props.data | null>(null);
  const isAdmin = useSelector(isUserAdmin);
  const isAdminViewingAccessTab = useSelector(isAdminViewingAccessTabAction);
  const isEditing = useSelector(isEditMode);
  const isEditTarget = useSelector(getEditTarget) === TargetTypes.LOGO;
  const isEditingGateLogo = useSelector(getIsEditingGateLogo);
  const [imgLoaded, setImgLoaded] = useState(true);
  const isActive = Boolean((
    isEditing ||
    isAdminViewingAccessTab ||
    isEditingGateLogo
  ) && isEditTarget);

  const closeView = () => {
    dispatch(clearEditTarget());
    dispatch(setIsEditingGateLogo(false));
  };

  const openView = () => {
    dispatch(setEditTarget(TargetTypes.LOGO));
    dispatch(setIsEditingGateLogo(true));
  };

  const onHeaderLogoClick = () => dispatch(onNavClick('headerLogo'));
  const saveLogo = (newData: LogoData) => dispatch(setPendingAdminChange(TargetTypes.LOGO, newData));

  useEffect(() => {
    if (!isEditTarget && isEditingGateLogo) {
      closeView();
    }
  }, [isEditTarget]);

  useEffect(() => {
    if (isActive) {
      if(!isAdminViewingAccessTab) {
        dispatch(navigateToAdminBarAction({ actionKey: ActionKey.logos }));
      } else {
        dispatch(setIsEditingGateLogo(true));
      }
    } else {
      setLogoData(null);
    }
  }, [isActive]);

  const handleSave = () => {
    const {
      data: oldData,
      saveGateLogo,
    } = props;
    const output = { ...oldData };

    if (logoData) {
      if (logoData.logoLink !== oldData.logoLink) {
        output.logoLink = logoData.logoLink;
      }
      if (logoData.logoUrl !== oldData.logoUrl) {
        output.logoUrl = logoData.logoUrl;
      }
      if (logoData.logoTitle !== oldData.logoTitle) {
        output.logoTitle = logoData.logoTitle;
      }
    }

    if (saveGateLogo && isAdminViewingAccessTab) {
      saveGateLogo(output);
    } else {
      saveLogo(output);
    }
  };

  const renderImage = (title?: string, url?: string) => {
    // NOTE: using noimage because passing back null presents an issue w some Image objects
    if (!url || url === NO_IMAGE || !imgLoaded) {
      return null;
    }

    const onError = () => {
      setImgLoaded(false);
    };
    const alt = title || 'logo';
    return (<Image alt={alt} className={LOGO} onError={onError} src={url} />);
  };

  const currentData = logoData || props.data;
  const checkedURL = !!(currentData.logoUrl !== NO_IMAGE && currentData.logoUrl);
  const logoTitle = endUserT([
    DynamicTranslationType.logoTitle,
    { pageId },
  ]) || currentData.logoTitle;

  const renderEditable = () => {
    const isChanged = !isEqual(currentData, props.data);

    return (
      <>
        {
          isEditingGateLogo && (
            <EditLogoBar
              closeView={closeView}
              data={currentData}
              isActive={isActive}
              isChanged={isChanged}
              onChange={setLogoData}
              saveChanges={handleSave}
            />
          )
        }
        <HeaderImageContainer hasImage={checkedURL} isAdmin={isAdmin}>
        {
          renderImage(
            !isEditing ? logoTitle : currentData.logoTitle,
            currentData.logoUrl,
          )
        }
        </HeaderImageContainer>
      </>
    );
  };

  const renderDefault = () => {
    return (
      <HeaderImageContainer hasImage={checkedURL} isAdmin={isAdmin}>
        {(checkedURL) && (
          <LogoLink href={currentData.logoLink} onClick={onHeaderLogoClick} plain={true}>
            {
              renderImage(logoTitle, currentData.logoUrl)
            }
          </LogoLink>
        )}
      </HeaderImageContainer>
    );
  };

  const renderHeaderImage = () => {
    if (isAdmin) {
      return renderEditable();
    }

    return renderDefault();
  };

  return (
    <StyledQuickEditOverlay
      isAdmin={isAdmin}
      testId="editModeLogo"
      titleKey="ADMIN_LABEL_LOGO"
      onEdit={openView}
      hasImage={checkedURL}
      isEditing={isEditing}
    >
      {renderHeaderImage()}
    </StyledQuickEditOverlay>
  );
}

const StyledQuickEditOverlay = styled(QuickEditOverlay)<{ hasImage: boolean; isAdmin: boolean; isEditing: boolean; }>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px 20px 20px;

  ${({ hasImage, isAdmin }) => !hasImage && isAdmin && css`
    min-width: 120px;
  `}

  ${({ hasImage, isEditing }) => hasImage && isEditing && css `
    min-width: fit-content;
    padding: 0px;
  `}

  ${({ isEditing, hasImage }) => isEditing && !hasImage && css`min-width: 65px;`}
`;

const HeaderImageContainer = styled.div<{ hasImage: boolean; isAdmin: boolean; }>`
  max-width: 360px;
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
  min-height: 75px;
  width: ${props => !props.hasImage && props.isAdmin ? '100px' : 'auto'};
`;

// NOTE - lement's display like this so that the img won't stretch
const LogoLink = styled(Link)<{ disabled?: boolean }>`
  display: inline-block;
  height: 100%;
  display: flex;
  min-height: 75px;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
`;
