import styled, { css, keyframes } from 'styled-components';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import Image from 'components/ui/Image';
import Icon from 'components/ui/Icon';
import VideoLink from 'components/ui/VideoLink';
import ScrollSlider from 'components/ui/ScrollSlider';
import ChannelSelector from 'components/ui/ChannelSelector';

import {
  BREAK_WORD,
  DIV_BUTTON,
  TITLE_FONT,
  TEXT_P1,
  TEXT_P2,
  TEXT_H6,
  THEME_PADDING,
  MULTI_LINE_ELLIPSIS,
} from 'style/mixins';
import {
  SPACING_SMALL,
  ADMIN_ACCENT_PRIMARY,
  TEXT_100,
  SURFACE_3,
  TITLE_FONT_FAMILY,
} from 'style/constants';
import { isTouchDevice } from 'utils';
import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';

const withPx = pxVal => pxVal !== 0 ? `${pxVal}px` : pxVal;

export const VIDEO_ROW_MIN_HEIGHT_DESKTOP = 268;
export const VIDEO_ROW_MIN_HEIGHT_MOBILE = 158;
export const PLAYLIST_ROW_MIN_HEIGHT_DESKTOP = 330;
export const PLAYLIST_ROW_MIN_HEIGHT_MOBILE = 220;
const PAGES_ROW_HEIGHT = 182;

export const THUMBNAIL_HEIGHT_LARGE_DESKTOP = 153;
export const THUMBNAIL_WIDTH_LARGE_DESKTOP = 272;
export const THUMBNAIL_HEIGHT_LARGE_MOBILE = 81;
export const THUMBNAIL_WIDTH_LARGE_MOBILE = 144;

export const THUMBNAIL_HEIGHT_SMALL_DESKTOP = 158;
export const THUMBNAIL_WIDTH_SMALL_DESKTOP = 316;
export const THUMBNAIL_HEIGHT_SMALL_MOBILE = 124;
export const THUMBNAIL_WIDTH_SMALL_MOBILE = 248;

export const THUMBNAIL_MARGIN_DESKTOP = 20;
export const THUMBNAIL_MARGIN_MOBILE = 14;

export const BUTTON_WIDTH_DESKTOP = 50;
export const BUTTON_WIDTH_MOBILE = 25;

export const DESKTOP_SIZES = {
  BUTTON_WIDTH: BUTTON_WIDTH_DESKTOP,
  CHANNEL_ROW_HEIGHT: PAGES_ROW_HEIGHT,
  PLAYLIST_ROW_MIN_HEIGHT: isTouchDevice ? PLAYLIST_ROW_MIN_HEIGHT_MOBILE :
    PLAYLIST_ROW_MIN_HEIGHT_DESKTOP,
  THUMBNAIL_HEIGHT_LARGE: THUMBNAIL_HEIGHT_LARGE_DESKTOP,
  THUMBNAIL_HEIGHT_SMALL: THUMBNAIL_HEIGHT_SMALL_DESKTOP,
  THUMBNAIL_MARGIN: THUMBNAIL_MARGIN_DESKTOP,
  THUMBNAIL_WIDTH_LARGE: THUMBNAIL_WIDTH_LARGE_DESKTOP,
  THUMBNAIL_WIDTH_SMALL: THUMBNAIL_WIDTH_SMALL_DESKTOP,
  VIDEO_ROW_HEIGHT: VIDEO_ROW_MIN_HEIGHT_DESKTOP,
};

export const MOBILE_SIZES = {
  BUTTON_WIDTH: BUTTON_WIDTH_MOBILE,
  CHANNEL_ROW_HEIGHT: PAGES_ROW_HEIGHT,
  PLAYLIST_ROW_MIN_HEIGHT: PLAYLIST_ROW_MIN_HEIGHT_MOBILE,
  THUMBNAIL_HEIGHT_LARGE: THUMBNAIL_HEIGHT_LARGE_MOBILE,
  THUMBNAIL_HEIGHT_SMALL: THUMBNAIL_HEIGHT_SMALL_MOBILE,
  THUMBNAIL_MARGIN: THUMBNAIL_MARGIN_MOBILE,
  THUMBNAIL_WIDTH_LARGE: THUMBNAIL_WIDTH_LARGE_MOBILE,
  THUMBNAIL_WIDTH_SMALL: THUMBNAIL_WIDTH_SMALL_MOBILE,
  VIDEO_ROW_HEIGHT: VIDEO_ROW_MIN_HEIGHT_MOBILE,
};

export const getSizes = isMobile => isMobile ? MOBILE_SIZES : DESKTOP_SIZES;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const GridContainer = styled.div<{ width?: number }>`
  opacity: 0;
  animation: ${fadeIn} 1.5s ease-in forwards;

  width: ${({ width }) => width ? `${width}px` : '100%'};
  max-width: 100%;
  margin: 0 auto;
`;

export const PlaylistSpinner = styled(LoadingSpinner)<{ isGridMobile: boolean; width?: number }>`
  height: ${({ isGridMobile }) => withPx(
    isGridMobile ?
      PLAYLIST_ROW_MIN_HEIGHT_MOBILE :
      PLAYLIST_ROW_MIN_HEIGHT_DESKTOP,
  )};
  margin: 0 auto;
  width: ${({ width }) => width}px;
`;

export const StyledQuickEditOverlay = styled(QuickEditOverlay)<{
  isGridMobile: boolean;
  minWidth?: number;
  showEditingBorder?: boolean;
}>`
  color: ${TEXT_100};
  margin: 0;
  padding: ${({ isGridMobile }) => withPx(isGridMobile ? '12' : '40')} 20px;
  width: 100%;
  max-width: 100%;
  padding-right: 0 !important;
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px`}
  ${props => props.showEditingBorder && (
    `
      width: 100%;
      position: relative;
      padding-left: 8px
      padding-right: 8px;
      overflow: hidden;
    `
  )}
  ${THEME_PADDING}
`;

export const PagesRowContainer = styled(StyledQuickEditOverlay)<{
  fullwidth: boolean,
  isGridMobile: boolean,
  showEditingBorder: boolean,
}>`
  min-height: auto;
  margin: auto 0;
  padding: ${({ isGridMobile }) => withPx(isGridMobile ? '12' : '35')} 20px;
  ${THEME_PADDING}
  padding-right: 0 !important;
  ${(props) => props.showEditingBorder && `
    position: relative;
    width: 100%;
    overflow: hidden;
  `}
`;

export const PlaylistHeader = styled.div`
  ${TEXT_P1}
`;

export const PlaylistTitle = styled.span`
  font-family: ${TITLE_FONT_FAMILY};
  margin-right: 18px;
`;

export const PlaylistCount = styled.span<{ isGridMobile?: boolean }>`
  opacity: ${({ isGridMobile }) => isGridMobile ? 0 : 0.5};
`;

export const PlaylistNumber = styled.span`
  margin-right: 0.25em;
`;

export const GridRowContainer = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const PlaylistThumbnail = styled.div<{ isGridMobile?: boolean }>`
  width: ${({ isGridMobile }) => withPx(
    isGridMobile ?
      THUMBNAIL_WIDTH_SMALL_MOBILE :
      THUMBNAIL_WIDTH_SMALL_DESKTOP,
  )};
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  aspect-ratio: 16/9;
  position: relative;
`;

export const PlaylistThumbnailImage = styled(Image)`
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 10px;
`;

export const DefaultThumbnailImage = styled.div`
  background-color: ${SURFACE_3};
  position: relative;
  height: 100%;
`;

export const PagePlaceholder = styled(DefaultThumbnailImage)<{
  height?: number;
  width?: number;
}>`
  height: ${({ height }) => withPx(height)};
  width: ${({ width }) => withPx(width)};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
`;

export const PlayButton = styled(Icon)`
  color: ${TEXT_100};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const PlaylistTimestamp = styled.div`
  ${TEXT_H6}
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  box-sizing: content-box !important;
  color: rgba(255, 255, 255, 0.75);
  height: 14px;
  padding: 2px;
  position: absolute;
  right: 0;
  text-align: center;
`;

export const PlaylistMetadata = styled.div`
  margin-top: 8px;
  ${TEXT_P2}
`;

export const VideoTitle = styled.div`
  line-height: 16px;
  font-family: ${TITLE_FONT_FAMILY};
  ${MULTI_LINE_ELLIPSIS}
`;

export const PlaylistItemWrapper = styled.div`
  flex-basis: 0;
  flex-grow: 1;
`;

export const PlaylistCreated = styled.div`
  color: ${TEXT_100};
  opacity: 0.5;
  margin-top: 6px;
`;

export const DesktopGridButton = styled.div<{
  active?: boolean;
  channels?: boolean;
  left?: boolean;
}>`
  color: ${TEXT_100};
  cursor: ${props => props.active ? 'pointer' : 'default'};
  font-size: 18px;
  width: ${BUTTON_WIDTH_DESKTOP}px;
  text-align: center;
  height: ${THUMBNAIL_HEIGHT_SMALL_DESKTOP}px;
  line-height: ${THUMBNAIL_HEIGHT_SMALL_DESKTOP}px;
  ${({ channels }) => channels && `margin-top: ${THUMBNAIL_MARGIN_DESKTOP}px;`}
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ left }) => left && `
    width: 0px;
    transition: width 0.25s;
  `}
  ${({ left, active }) => left && active && `
    width: ${BUTTON_WIDTH_DESKTOP}px;
  `}
`;

export const PagesGridButton = styled(DesktopGridButton)`
  height: ${VIDEO_ROW_MIN_HEIGHT_MOBILE}px;
`;

export const MobileGridButton = styled.div<{ active?: boolean }>`
  cursor: ${props => props.active ? 'pointer' : 'default'};
  ${TEXT_H6}
  width: 25px;
  display: flex;
  align-items: center;
`;

export const RowTitle = styled.div`
  ${TITLE_FONT};
  margin: 5px 0;
  ${TEXT_P1}
`;

export const EmptyButton = styled(DesktopGridButton)`
  display: none;
`;

export const VideoSpinner = styled(LoadingSpinner)<{
  isGridMobile?: boolean;
  showBorder: number;
  width?: number;
}>`
  height: ${({ showBorder, isGridMobile }) => withPx(
    (isGridMobile ?
      VIDEO_ROW_MIN_HEIGHT_MOBILE :
      VIDEO_ROW_MIN_HEIGHT_DESKTOP) - 35 * showBorder,
  )};
  margin: 0 auto;
  width: ${props => props.width}px;
`;

export const VideoContainer = styled.div<{ isGridMobile: boolean; preventClicks: boolean; }>`
  padding-top: ${({ isGridMobile }) => isGridMobile ? 15 : 35}px;
  padding-bottom: ${({ isGridMobile }) => ((isGridMobile) ? 15 : 35)}px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  pointer-events: ${props => props.preventClicks ? 'none' : 'auto'};
`;

export const Container = styled.div<{ showEditingBorder?: boolean; width?: number; }>`
  margin: 0;
  width: ${props => props.width ? `${props.width}px` : '100%'};
  ${props => props.showEditingBorder && css`
    position: relative;
    padding-left: 8px;
    padding-right: 8px;
    &, & > ${VideoContainer} {
      width: 100%;
    }
  `}
`;

export const Button = styled.div`
  position: absolute;
  top: 0;
  background-color: ${ADMIN_ACCENT_PRIMARY};;
  color: white;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${DIV_BUTTON};
`;

export const Ex = styled(Icon).attrs(() => ({
  name: 'cancel-thin',
}))`
  font-size: 16px;
`;

export const Pencil = styled(Icon).attrs(() => ({
  color: 'white',
  name: 'pencil',
}))`
  color: white;
  margin-bottom: 4px;
  & > svg {
    height: 18px;
    width: 18px;
  }
`;

export const Thumbnail = styled.div<{ image?: string; isGridMobile?: boolean; }>`
  height: ${({ isGridMobile }) => withPx(
    isGridMobile ?
      THUMBNAIL_HEIGHT_LARGE_MOBILE :
      THUMBNAIL_HEIGHT_LARGE_DESKTOP,
  )};
  background: url(${props => props.image}) no-repeat center;
  background-size: cover;
  flex: none;
  position: relative;
  text-align: center;
  width: ${({ isGridMobile }) => withPx(
    isGridMobile ?
      THUMBNAIL_WIDTH_LARGE_MOBILE :
      THUMBNAIL_WIDTH_LARGE_DESKTOP,
  )};
`;

export const ThumbnailImage = styled(Image)`
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 10px;
`;

export const TimeStamp = styled.div`
  ${TEXT_H6}
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  box-sizing: content-box !important;
  color: rgba(255, 255, 255, 0.75);
  height: 14px;
  padding: 2px;
  position: absolute;
  right: 0;
`;

export const Metadata = styled.div<{ isGridMobile?: boolean }>`
  margin-left: ${({ isGridMobile }) => isGridMobile ? 5 : 30}px;
`;

export const Title = styled.div<{ isGridMobile?: boolean }>`
  font-size: ${({ isGridMobile }) => isGridMobile ? 13 : 24}px;
  line-height: ${({ isGridMobile }) => isGridMobile ? 16 : 28}px;
  margin-bottom: ${({ isGridMobile }) => isGridMobile ? 5 : 15}px;
`;

export const Created = styled.div`
  color: ${TEXT_100};
  ${TEXT_H6}
  margin-bottom: ${SPACING_SMALL};
  opacity: 0.5;
`;

export const Description = styled.div<{ isGridMobile?: boolean }>`
  ${TEXT_P1}
  display: ${({ isGridMobile }) => isGridMobile ? 'none' : 'initial'};
  color: ${TEXT_100};
  white-space: pre-wrap;
  opacity: 0.5;
  ${BREAK_WORD}
`;

export const StyledVideoLink = styled(VideoLink).attrs(() => ({
  plain: true,
}))`
  && {
    color: ${TEXT_100};
  }
`;

export const CloseButton = styled(Button)`
  right: 0;
`;

export const EditButton = styled(Button)`
  left: 0;
  padding-bottom: 2px;
`;

export const ClickPreventer = styled.div<{ preventClicks: boolean }>`
  pointer-events: ${props => props.preventClicks ? 'none' : 'auto'};
`;

export const PageSlideItem = styled(ChannelSelector)<{ width: number }>`
  cursor: pointer;
  display: flex;
  position: relative;
  user-select: none;
  width: ${props => props.width}px;

  &:nth-of-type(3n + 2) {
    margin: 0 16px;
  }
`;
// @ts-ignore
export const StyledSlider = styled(ScrollSlider)`
  width: 100%;
  justify-content: center;
  height: 100%;
  display: flex;
`;
