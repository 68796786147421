import React, { useState, useCallback } from 'react';
import { useTheme } from 'styled-components';
import { findIndex } from 'lodash';
import { useDispatch, useSelector } from 'hooks';
import hash from 'json-stable-stringify';
import { getSiteId } from 'services/app/selectors';
import { setPendingAdminChange, TargetTypes } from 'services/admin';
import PlaylistLibraryModal from 'components/admin2/videoPanels/PlaylistLibraryModal';
import TaggedVideosEditorModal from 'components/admin2/videoPanels/TaggedVideosEditorModal';
import { DEFAULT_IMAGE_GALLERY } from 'components/page-blocks/ImageGallery/utils';
import shortid from 'shortid';
import { DEFAULT_TEXT_BANNER } from 'components/page-blocks/TextBanner/TextBanner.utils';
import { DEFAULT_FAQ } from 'components/page-blocks/FAQ/utils';
import { getDefaultHeroBanner } from 'components/page-blocks/HeroBanner/utils';
import { DEFAULT_IMAGE_AND_TEXT } from 'components/page-blocks/ImageAndText/utils';
import { DEFAULT_VIDEO_SPOTLIGHT } from 'components/page-blocks/VideoSpotlight/utils';
import { useAdminTranslation } from 'hooks/use-translation';
import { getDefaultEventCalendar } from 'components/page-blocks/EventCalendar/utils';
import { getDefaultRichTextBlock } from 'components/page-blocks/RichText/utils';
import { getPendingOrActualLandingPageContent } from 'services/admin/selectors';
import {
  IEventCalendarBlock,
  IFAQBlock,
  IHeroBannerBlock,
  IImageAndTextBlock,
  IImageGalleryBlock,
  ILandingPageContent,
  ILandingPageContentBlockKinds,
  IPagesRowBlock,
  IPagesRowPage,
  IRichTextBlock,
  IShopifyBlock,
  IEmbedBlock,
  ITaggedVideosBlock,
  ITextBannerBlock,
  IVideoSpotlightBlock,
} from 'services/app/models/ILandingPageContent';
import { ButtonModalWrapper, NoContent, NoContentDescription, NoContentTitle, StyledLargeButton } from './styles';
import AdminGridView from './AdminGridView';
import ChannelSelectModal from './ChannelSelectModal';
import AddBlockModal from './AddBlockModal';
import { DEFAULT_SHOPIFY } from 'components/page-blocks/Shopify/utils';

const AdminGridController = () => {
  const { t } = useAdminTranslation();
  const landingContent = useSelector(getPendingOrActualLandingPageContent) || [];
  const [activeModalIndex, setActiveModalIndex] = useState(-1);
  const [playlistModalOpen, setPlaylistModalOpen] = useState(false);
  const [pagesRowEditorOpen, setPagesRowEditorOpen] = useState(false);
  const [taggedVideosEditorOpen, setTaggedVideosEditorOpen] = useState(false);
  const [taggedVideo, setTaggedVideo] = useState<ITaggedVideosBlock | null>(null);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editingType, setEditingType] = useState<ILandingPageContentBlockKinds | null>(null);
  const [pagesRowEditingItem, setPagesRowEditingItem] = useState<IPagesRowBlock | null>(null);
  const [pagesRowEditingIndex, setPagesRowEditingIndex] = useState<number | null>(null);
  const [showAddBlockModal, setShowAddBlockModal] = useState(false);

  const dispatch = useDispatch();
  const theme = useTheme();
  const siteId = useSelector(getSiteId);

  const onGridContentEdit = useCallback((type: ILandingPageContentBlockKinds, i: number) => {
    setEditingType(type);
    setEditingIndex(i);
  }, []);

  const onTaggedVideoEdit = useCallback((taggedVideoData: ITaggedVideosBlock) => {
    const taggedVideoBlockIndex = findIndex(
      landingContent,
      (item) => item.arrayId === taggedVideoData.arrayId,
    );

    if (taggedVideoBlockIndex === -1 || !landingContent[taggedVideoBlockIndex]) {
      return;
    }

    const taggedVideoBlock = landingContent[taggedVideoBlockIndex] as ITaggedVideosBlock;

    setTaggedVideo(taggedVideoBlock);
    setEditingType('taggedVideos');
    setTaggedVideosEditorOpen(true);
  }, [hash(landingContent)]);

  const onPageBlockEdit = useCallback((pageBlockItem: ILandingPageContent) => {
    const index = findIndex(landingContent, (item) => item.arrayId === pageBlockItem.arrayId);
    if (index === -1) {
      return;
    }

    const newGridItems = [...landingContent];
    newGridItems[index] = pageBlockItem;
    publishLandingPageContent(newGridItems);
  }, [hash(landingContent)]);

  const clearPagesState = useCallback(() => {
    setPagesRowEditingItem(null);
    setPagesRowEditingIndex(null);
    setPagesRowEditorOpen(false);
  }, []);

  const onPagesRowEdit = useCallback((index: number, pagesRowData: IPagesRowBlock) => {
    setPagesRowEditingItem(pagesRowData);
    setPagesRowEditingIndex(index);
    setPagesRowEditorOpen(true);
  }, []);

  const handlePagesRowEdit = useCallback((pagesRowData: IPagesRowBlock) => {
    if (pagesRowEditingIndex === null) {
      return;
    }

    const newLandingContent = [...landingContent];
    newLandingContent.splice(pagesRowEditingIndex, 1, pagesRowData);
    clearPagesState();

    publishLandingPageContent(newLandingContent);
  }, [hash(landingContent), pagesRowEditingIndex]);

  const resetEditingState = useCallback(() => {
    setEditingType(null);
    setEditingIndex(-1);
    setTaggedVideo(null);
  }, []);

  const replaceGridItem = useCallback((kind: ILandingPageContentBlockKinds) => ({ _id }) => {
    const newGridItem = {
      arrayId: shortid.generate(),
      kind,
      [kind]: {
        _id,
      },
    };

    const itemsCopy = [...landingContent];
    // @ts-ignore
    itemsCopy.splice(editingIndex, 1, newGridItem);
    resetEditingState();


    publishLandingPageContent(itemsCopy);
  }, [hash(landingContent), editingIndex]);

  const addGridItemToIndex = useCallback((newGridItem: any) => {
    setShowAddBlockModal(false);

    const landingContentCopy = [...landingContent];
    landingContentCopy.splice(activeModalIndex, 0, newGridItem);
    publishLandingPageContent(landingContentCopy);
  }, [hash(landingContent), activeModalIndex]);

  const addGridItem = useCallback((kind: ILandingPageContentBlockKinds) => ({ _id }) => {
    const newGridItem = {
      arrayId: shortid.generate(),
      kind,
      [kind]: {
        _id,
      },
    };
    addGridItemToIndex(newGridItem);
    setPlaylistModalOpen(false);
  }, [addGridItemToIndex]);

  const removeGridItem = useCallback((index: number) => {
    const newLandingContent = [...landingContent];
    newLandingContent.splice(index, 1);
    publishLandingPageContent(newLandingContent);
  }, [hash(landingContent)]);

  const publishLandingPageContent = useCallback((newGridItems: ILandingPageContent[]) => {
    dispatch(setPendingAdminChange(
      TargetTypes.LANDING,
      newGridItems,
      true,
    ));
  }, []);

  const addImageGalleryBlock = useCallback(() => {
    const newGridItem: IImageGalleryBlock = {
      arrayId: shortid.generate(),
      data: DEFAULT_IMAGE_GALLERY(t),
      kind: 'imageGallery',
    };
    addGridItemToIndex(newGridItem);
  }, [addGridItemToIndex, t]);

  const addVideoTagBlock = useCallback((taggedVideosBlock: any) => {
    const newGridItem: ITaggedVideosBlock = {
      arrayId: shortid.generate(),
      kind: 'taggedVideos',
      ...taggedVideosBlock,
    };
    addGridItemToIndex(newGridItem);
    setTaggedVideosEditorOpen(false);
  }, [addGridItemToIndex]);

  const onBackVideoTagBlock = useCallback(() => {
    setTaggedVideosEditorOpen(false);
    resetEditingState();
  }, []);

  const saveVideoTagBlock = useCallback((taggedVideosBlock: any) => {
    if (!taggedVideo) {
      return;
    }

    const taggedVideoBlockIndex = findIndex(landingContent, (item) => item.arrayId === taggedVideo.arrayId);
    const updatedBlock = { ...landingContent[taggedVideoBlockIndex], ...taggedVideosBlock };

    const itemsCopy = [...landingContent];
    itemsCopy.splice(taggedVideoBlockIndex, 1, updatedBlock);
    publishLandingPageContent(itemsCopy);

    onBackVideoTagBlock();
  }, [hash(landingContent), taggedVideo]);

  const handleAddDefaultTextBanner = useCallback(() => {
    const textBanner: ITextBannerBlock = {
      arrayId: shortid.generate(),
      data: DEFAULT_TEXT_BANNER(t),
      kind: 'textBanner',
    };
    addGridItemToIndex(textBanner);
  }, [addGridItemToIndex, t]);

  const handleAddDefaultFAQ = useCallback(() => {
    const faqV1: IFAQBlock = {
      arrayId: shortid.generate(),
      data: DEFAULT_FAQ(t),
      kind: 'faqV1',
    };
    addGridItemToIndex(faqV1);
  }, [addGridItemToIndex, t]);

  const handleAddHeroBanner = useCallback(() => {
    const heroBanner: IHeroBannerBlock = {
      arrayId: shortid.generate(),
      data: getDefaultHeroBanner(t, theme?.site.colors?.surface1),
      kind: 'heroBanner',
    };
    addGridItemToIndex(heroBanner);
  }, [addGridItemToIndex, theme?.site.colors?.surface1, t]);

  const handleAddImageAndText = useCallback(() => {
    const heroBanner: IImageAndTextBlock = {
      arrayId: shortid.generate(),
      data: DEFAULT_IMAGE_AND_TEXT(t),
      kind: 'imageAndText',
    };
    addGridItemToIndex(heroBanner);
  }, [addGridItemToIndex, t]);

  const handleAddVideoSpotlight = useCallback(() => {
    const videoSpotlight: IVideoSpotlightBlock = {
      arrayId: shortid.generate(),
      data: DEFAULT_VIDEO_SPOTLIGHT,
      kind: 'videoSpotlight',
    };
    addGridItemToIndex(videoSpotlight);
  }, [addGridItemToIndex]);

  const handleAddEventCalendar = useCallback(() => {
    const eventCalendar: IEventCalendarBlock = {
      arrayId: shortid.generate(),
      data: getDefaultEventCalendar(),
      kind: 'eventCalendar',
    };
    addGridItemToIndex(eventCalendar);
  }, [addGridItemToIndex]);

  const handleAddRichTextBlock = useCallback(() => {
    const richText: IRichTextBlock = {
      arrayId: shortid.generate(),
      data: getDefaultRichTextBlock(theme?.site.colors?.surface1),
      kind: 'richText',
    };
    addGridItemToIndex(richText);
  }, [addGridItemToIndex, theme?.site.colors?.surface1]);

  const handleAddShopifyBlock = useCallback(() => {
    const shopifyBlock: IShopifyBlock = {
      arrayId: shortid.generate(),
      data: { ...DEFAULT_SHOPIFY, showProductName: false, showProductPrice: false },
      kind: 'shopify',
    };
    addGridItemToIndex(shopifyBlock);
  }, [addGridItemToIndex]);

  const handleAddEmbedBlock = useCallback(() => {
    const embedBlock: IEmbedBlock = {
      arrayId: shortid.generate(),
      data: { url: '' },
      kind: 'embedCode',
    };
    addGridItemToIndex(embedBlock);
  }, [addGridItemToIndex]);

  const handleOpenPagesRowEditor = useCallback(() => {
    setPagesRowEditorOpen(true);
  }, []);

  const handleOpenPlaylistModal = useCallback(() => {
    setPlaylistModalOpen(true);
  }, []);

  const handleClosePlaylistModal = useCallback(() => {
    setPlaylistModalOpen(false);
  }, []);

  const handleOpenTaggedVideosEditor = useCallback(() => {
    setTaggedVideosEditorOpen(true);
  }, []);

  const handleCloseAddBlockModal = useCallback(() => {
    setShowAddBlockModal(false);
  }, []);

  const handleOpenAddBlockModal = useCallback(() => {
    setShowAddBlockModal(true);
  }, []);

  const handleAddClick = useCallback((index: number) => {
    setShowAddBlockModal(true);
    setActiveModalIndex(index);
  }, []);

  const handleAddPagesRow = React.useCallback(({ pages, titleText }: { pages: IPagesRowPage[]; titleText: string; }) => {
    const newGridItem: IPagesRowBlock = {
      arrayId: shortid.generate(),
      kind: 'pagesRow',
      pages,
      titleText,
    };
    addGridItemToIndex(newGridItem);
    setPagesRowEditorOpen(false);
  }, [addGridItemToIndex]);

  const renderAddBlockModal = () => showAddBlockModal ? (
    <AddBlockModal
      onAddChannelNavigator={handleOpenPagesRowEditor}
      onAddEventCalendar={handleAddEventCalendar}
      onAddFAQ={handleAddDefaultFAQ}
      onAddHeroBanner={handleAddHeroBanner}
      onAddImageAndText={handleAddImageAndText}
      onAddImageGallery={addImageGalleryBlock}
      onAddPlaylist={handleOpenPlaylistModal}
      onAddRichText={handleAddRichTextBlock}
      onAddTaggedVideo={handleOpenTaggedVideosEditor}
      onAddTextBanner={handleAddDefaultTextBanner}
      onAddVideoSpotlight={handleAddVideoSpotlight}
      onAddShopifyBlock={handleAddShopifyBlock}
      onAddEmbedCode={handleAddEmbedBlock}
      onClose={handleCloseAddBlockModal}
    />
  ) : null;

  return (
    <>
      {playlistModalOpen && (
        <PlaylistLibraryModal
          onClose={handleClosePlaylistModal}
          onSelectItem={addGridItem('playlist')}
        />
      )}
      {editingIndex > -1 && editingType === 'playlist' && (
        <PlaylistLibraryModal
          onClose={resetEditingState}
          onSelectItem={replaceGridItem('playlist')}
        />
      )}
      {pagesRowEditorOpen && (
        <ChannelSelectModal
          data={pagesRowEditingItem}
          handleAdd={handleAddPagesRow}
          onClose={clearPagesState}
          onSave={handlePagesRowEdit}
          siteId={siteId}
        />
      )}
      {taggedVideosEditorOpen && (
        <TaggedVideosEditorModal
          data={taggedVideo}
          handleAdd={addVideoTagBlock}
          handleSave={saveVideoTagBlock}
          isOpen={taggedVideosEditorOpen}
          onClose={onBackVideoTagBlock}
        />
      )}
      {landingContent.length > 0 ? renderAddBlockModal() : (
        <NoContent>
          <NoContentTitle>{t('ADMIN_LABEL_LANDING_PAGE_NO_CONTENT_TITLE')}</NoContentTitle>
          <NoContentDescription>
            {t('ADMIN_LABEL_LANDING_PAGE_NO_CONTENT_DESCRIPTION')}
            <br/>
            {t('ADMIN_LABEL_LANDING_PAGE_NO_CONTENT_DESCRIPTION_2')}
          </NoContentDescription>
          <ButtonModalWrapper>
            <StyledLargeButton onClick={handleOpenAddBlockModal}>
              + {t('LABEL_ADD_BLOCK')}
            </StyledLargeButton>
            {renderAddBlockModal()}
          </ButtonModalWrapper>
        </NoContent>
      )}
      <AdminGridView
        activeModalIndex={activeModalIndex}
        landingContent={landingContent}
        onAdd={handleAddClick}
        onGridContentEdit={onGridContentEdit}
        onPageBlockEdit={onPageBlockEdit}
        onPagesRowEdit={onPagesRowEdit}
        onRemove={removeGridItem}
        onReorder={publishLandingPageContent}
        onTaggedVideoEdit={onTaggedVideoEdit}
      />
    </>
  );
};

export default AdminGridController;
