import React from 'react';
import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import { HIGHLIGHT_PRIMARY, SURFACE_1, SURFACE_4 } from 'style/constants';
import { BREAK_WORD, mobileOnly, CIRCLE, FLEX_CENTER } from 'style/mixins';
import { isMobileLayout } from 'services/device/selectors';
import { useSelector } from 'react-redux';

interface CardImageProps extends React.HTMLAttributes<HTMLDivElement> {
  background?: string;
  dim?: boolean;
  isCollapsed?: boolean;
  justify?: string;
  maxHeight?: number;
  onClose?: () => void;
  responsive?: boolean;
  src?: string;
}

const CARD_HEIGHT = 178;

const CardWrapper = styled.div<{maxHeight: number, responsive?: boolean}>`
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: ${({ maxHeight }) => maxHeight}px;
  border-radius: 10px;
  ${BREAK_WORD}
  ${mobileOnly`
    box-shadow: 0px 0px 11px ${SURFACE_1};
  `}
  ${({ responsive, maxHeight }) => responsive && `
    max-height: ${maxHeight}px;
    height: auto;
  `}
`;

const Image = styled.div<CardImageProps>`
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  max-height: ${({ maxHeight }) => maxHeight}px;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ background }) => background || SURFACE_4};
  background-image: url("${({ src }) => src}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
`;

const Background = styled.div<{dim?: boolean, hasImage?: boolean, justify: string}>`
  ${({ dim, hasImage }) => dim && hasImage && `
    background: linear-gradient(126.07deg, rgba(0, 0, 0, 0.74) 2.13%, rgba(0, 0, 0, 0.13) 114.34%);
  `}
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => justify};
  align-items: flex-start;
`;

const CloseHitbox = styled.div.attrs({
  radius: '40px',
})<{collapsed?: boolean}>`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  ${CIRCLE}
  ${FLEX_CENTER}
  ${({ collapsed }) => collapsed && 'pointer-events: none;'}
`;

const CloseButton = styled(Icon).attrs((props) => ({
  color: HIGHLIGHT_PRIMARY(props),
  name: 'cancel-thin',
}))<{collapsed?: boolean}>`
  position: absolute;
  right: 14.7px;
  top: 13px;
  z-index: 2;
  line-height: 0;
  cursor: pointer;
  transition: opacity 1s, max-height 1s, overflow 1s;
  background: ${SURFACE_1};
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  ${({ collapsed }) => mobileOnly`
    opacity: ${collapsed ? 0 : 1};
    max-height: ${collapsed ? 0 : 50}px;
    overflow: ${collapsed ? 'hidden' : 'visible'};
  `}
`;

export default function CardImage({
  children,
  className,
  dim,
  isCollapsed,
  justify = 'space-between',
  onClick,
  onClose,
  responsive,
  maxHeight = CARD_HEIGHT,
  src,
}: CardImageProps) {
  const isMobile = useSelector(isMobileLayout);

  return (
    <CardWrapper data-testid="liveCardItem" className={className} maxHeight={maxHeight} responsive={responsive}>
    {isMobile ? (
      <CloseHitbox collapsed={isCollapsed} onClick={onClose}>
        <CloseButton collapsed={isCollapsed} />
      </CloseHitbox>
    ) : <CloseButton collapsed={isCollapsed} onClick={onClose} />}
      <Image
        onClick={onClick}
        src={src}
      />
      <Background dim={dim} hasImage={!!src} justify={justify}>{children}</Background>
    </CardWrapper>
  );
}
