import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import InsightsViewable from 'components/insights/InsightsViewable';
import { SURFACE_1, SURFACE_2, TEXT_100 } from 'style/constants';
import { mobileOnly, desktopOnly } from 'style/mixins';
import ImageCard from 'components/cards/ImageCard';
import MessageCard from 'components/cards/MessageCard';
import PersonCard from 'components/cards/PersonCard';
import PollCard from 'components/cards/PollCard';
import TweetCard from 'components/cards/TweetCard';
import Draggable from 'components/ui/Draggable';
import { Transition } from 'react-transition-group';
import {
  computeCardRendererFromDoc,
} from 'services/renderer';

const CARD_HEIGHT = 198;
const fullyHiddenPos = (maxHeight = CARD_HEIGHT) => -maxHeight;
const cardHiddenBottom = (maxHeight = CARD_HEIGHT) => fullyHiddenPos(maxHeight) + 80;
const mobileCardHeight = (maxHeight = CARD_HEIGHT) => maxHeight + 8;

const Container = styled(Draggable)`
  touch-action: none;
  background-color: ${SURFACE_2};
  color: ${TEXT_100};
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  overflow: hidden !important;
  ${mobileOnly`
    position: absolute;
    background-color: transparent;
    transition: transform 0.5s, min-height 0.5s, bottom 0.5s;
    min-height: ${({ collapsed, maxHeight }) => collapsed ? mobileCardHeight(maxHeight) : 0}px;
    bottom: ${({ position, state, maxHeight }) => state === 'entered' ? position : fullyHiddenPos(maxHeight)}px;
  `}
  ${desktopOnly`
    transition: max-height 0.5s;
    max-height: ${({ state, maxHeight }) => state === 'entered' ? maxHeight : 0}px;
    transform: none !important;
  `}
`;

const CardContainer = styled.div`
  touch-action: none;
  padding: 0 10px 10px 10px;
`;

const EmptyCard = styled.div`
  touch-action: none;
  height: ${({ maxHeight }) => maxHeight}px;
`;

const ExpandIcon = styled(Icon).attrs(() => ({
  name: 'upArrowGrad',
}))`
  touch-action: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  background: ${SURFACE_1};
  border-radius: 10px 10px 0 0;
  margin: 0 auto -10px auto;
  padding: 5px 0;
  transition: opacity 1s, max-height 1s, margin 0s 1s, padding 0s 1s;
  z-index: 2;
  ${({ collapsed }) => collapsed ? `
    opacity: 1;
    max-height: 50px;
    transition: opacity 1s;
  ` : `
    max-height: 0;
    margin: 0 auto;
    padding: 0;
    opacity: 0;
  `}
  & svg {
    margin: -12px;
    width: 32px;
    height: 32px;
  }
  ${desktopOnly`
    display: none;
  `}
`;

const CARD_MAP = {
  image: {
    component: ImageCard,
  },
  message: {
    component: MessageCard,
  },
  person: {
    component: PersonCard,
    maxHeight: 513,
  },
  poll: {
    component: PollCard,
  },
  tweet: {
    component: TweetCard,
    iconName: 'retweet',
  },
};

const noop = () => {};

export const CARD_VIEW_PREVIEW_PROPS = {
  cardKey: '__card_preview__',
  isCollapsed: false,
  isDismissed: false,
  isPreview: true,
  loggedIn: true,
  onCollapse: noop,
  onDismiss: noop,
  onOpen: noop,
  openPanel: noop,
  showModal: noop,
  trackCard: noop,
};

export default class Card extends React.Component {
  static propTypes = {
    cardKey: PropTypes.string.isRequired,
    className: PropTypes.string,
    doc: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    hidden: PropTypes.bool,
    isDismissed: PropTypes.bool.isRequired,
    isPreview: PropTypes.bool,
    loggedIn: PropTypes.bool.isRequired,
    mockCardRenderer: PropTypes.object,
    onDismiss: PropTypes.func.isRequired,
    openPanel: PropTypes.func.isRequired,
    rendererDraft: PropTypes.shape({}),
    showModal: PropTypes.func.isRequired,
    trackCard: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
    hidden: false,
    isPreview: false,
    mockCardRenderer: null,
    rendererDraft: undefined,
  };

  state = {
    cardPosition: 0,
    finalPosition: -1,
  };

  computeRenderer = memoizeOne(computeCardRendererFromDoc);

  hasRenderer = false;

  componentDidUpdate() {
    const { finalPosition } = this.state;
    if (finalPosition < 0 && !this.hasRenderer && this.renderer) {
      this.hasRenderer = true;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ finalPosition: cardHiddenBottom(this.maxHeight) });
    }
  }

  get maxHeight() {
    const { maxHeight = CARD_HEIGHT } = CARD_MAP[this.renderer?.cardType] || {};
    return maxHeight;
  }

  get renderer() {
    const { doc, rendererDraft, mockCardRenderer } = this.props;
    if (!doc) {
      return null;
    }
    if (rendererDraft !== undefined || Boolean(mockCardRenderer)) {
      return mockCardRenderer || rendererDraft;
    }
    return this.computeRenderer(doc);
  }

  get isCollapsed() {
    const { finalPosition } = this.state;
    return !!finalPosition;
  }

  onSlide = (x, y) => {
    this.setState({ cardPosition: y });
  };

  onSlideEnd = (x, y, e) => {
    if (e.target instanceof HTMLButtonElement) {
      this.setState({ cardPosition: 0 });
      return;
    }
    if (!y) {
      this.setState(({ finalPosition }) => ({
        cardPosition: 0,
        finalPosition: finalPosition ? 0 : cardHiddenBottom(this.maxHeight),
      }));
      return;
    }
    this.setState({
      cardPosition: 0,
      finalPosition: y > 0 ? cardHiddenBottom(this.maxHeight) : 0,
    });
  };

  handleDismiss = () => {
    const { cardKey, onDismiss } = this.props;
    onDismiss(cardKey, this.renderer);
  };

  renderInnerCard() {
    const {
      doc,
      openPanel,
      showModal,
      trackCard,
      loggedIn,
    } = this.props;
    const { handleDismiss, renderer, isCollapsed } = this;

    if (!renderer) {
      return <EmptyCard />;
    }

    const { component: Component, iconName } = CARD_MAP[renderer.cardType] || {};
    if (!Component) {
      return <EmptyCard />;
    }

    return (
      <Component
        doc={doc} // TODO: Eventually remove?
        iconName={iconName}
        isCollapsed={isCollapsed}
        loggedIn={loggedIn}
        onDismiss={handleDismiss}
        openPanel={openPanel}
        renderer={renderer}
        showModal={showModal}
        trackCard={trackCard}
      />
    );
  }

  render() {
    const {
      className,
      doc,
      hidden,
      isDismissed,
      isPreview,
    } = this.props;

    const { renderer, onSlide, onSlideEnd, onSlideStart, isCollapsed } = this;
    const { cardPosition, finalPosition } = this.state;

    return (
      <Transition in={doc && !isDismissed} mountOnEnter timeout={500} unmountOnExit>
        {(state) => (
          <Container
            cardPosition={cardPosition}
            className={className}
            collapsed={isCollapsed}
            hidden={hidden}
            maxHeight={this.maxHeight}
            onEnd={onSlideEnd}
            onMove={onSlide}
            onStart={onSlideStart}
            position={finalPosition}
            state={state}
            y={cardPosition}
          >
            {
              !isPreview && renderer && (
                <InsightsViewable
                  doc={renderer}
                  kingdom="card"
                  visible={!hidden}
                />
              )
            }
            {doc ? (
              <>
                <ExpandIcon collapsed={isCollapsed} />
                <CardContainer>
                  {this.renderInnerCard()}
                </CardContainer>
              </>
            ) : <EmptyCard />}
          </Container>
        )}
      </Transition>
    );
  }
}
