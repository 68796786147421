import React from 'react';
import { useDispatch } from 'react-redux';
import { updateOrientationDegrees } from 'services/device/actions';

export default function ScreenOrientation() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (screen.orientation) {
      const handleEvent = () => {
        switch (screen.orientation.type) {
          case 'portrait-primary': dispatch(updateOrientationDegrees(0)); break;
          case 'portrait-secondary': dispatch(updateOrientationDegrees(180)); break;
          case 'landscape-primary': dispatch(updateOrientationDegrees(90)); break;
          case 'landscape-secondary': dispatch(updateOrientationDegrees(-90)); break;
        }
      };
      screen.orientation.addEventListener('change', handleEvent);
      handleEvent();

      return () => {
        screen.orientation.removeEventListener('change', handleEvent);
      };
    } else { // For iOS Safari
      const handleEvent = () => {
        if (typeof window.orientation === 'number') {
          dispatch(updateOrientationDegrees(window.orientation));
        }
      };
      window.addEventListener('orientationchange', handleEvent);
      handleEvent();

      return () => {
        window.removeEventListener('orientationchange', handleEvent);
      };
    }
  }, []);

  return null;
}
