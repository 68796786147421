import { useCallback } from 'react';
import { trackVideo as trackVideoAction } from 'services/insights';
import { IVideoPlayback } from 'services/video/interfaces';
import { getVideoModalId } from 'services/video/selectors';
import { isMobileLayout, isLandscape } from 'services/device/selectors';
import {
  getPanelState,
  isFullscreenActive,
  isTheaterActive,
} from 'services/user-layout/selectors';
import { showVideoModal } from 'services/video/actions';
import { updateWantsSidebar, setViewMode } from 'services/user-layout/actions';
import { getPlayerSocials } from 'services/app/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { isModalKindOpen } from 'services/modals/selectors';
import IState from 'services/state';
import { useVideoPlayerContext } from './video-player/Player/Context';

export type VideoOverlayState = ReturnType<typeof useVideoOverlayState>;

export default function useVideoOverlayState() {
  const dispatch = useDispatch();
  const { playerState } = useVideoPlayerContext();

  return {
    socialsData: useSelector(getPlayerSocials),
    fullscreenActive: useSelector(isFullscreenActive),
    isLandscape: useSelector(isLandscape),
    isLive: playerState.isLive,
    isMobileLayout: useSelector(isMobileLayout),
    isPlaying: playerState.playing,
    isTextModalOpen: useSelector((state: IState) =>
      isModalKindOpen(state, 'selectableText'),
    ),
    videoMIMEType: playerState.videoMIMEType,
    spot: playerState.spot,
    panelState: useSelector(getPanelState),
    theaterModeActive: useSelector(isTheaterActive),
    video: playerState.video,
    videoDuration: playerState.duration,
    videoKind: playerState.video?.player,
    videoModalId: useSelector(getVideoModalId),
    videoOffset: playerState.offset,
    videoTitle: playerState.video.title ?? null,
    videoUrl: playerState.video.url,
    muted: playerState.muted,
    volume: playerState.volume,
    onPlaybackUpdate: (playState: Partial<IVideoPlayback>) => {
      playerState.onPlaybackUpdate(playState);
    },
    seekVideo: useCallback(
      (params: { playedSeconds: number }) => {
        playerState.seekVideo(params.playedSeconds);
      },
      [playerState.seekVideo],
    ),
    setViewMode: (mode: Parameters<typeof setViewMode>[0]) => {
      dispatch(setViewMode(mode));
    },
    showVideoModal: (modalId: string | null = null) => {
      dispatch(showVideoModal({ modalId }));
    },
    trackVideo: useCallback(
      (...params: Parameters<typeof trackVideoAction>) =>
        dispatch(trackVideoAction(...params)),
      [],
    ),
    updateWantsSidebar: (toggle: boolean) =>
      dispatch(updateWantsSidebar(toggle)),
  };
}
