import { ObjectValues } from 'utils';
import { CTAVariant } from '../../ui/CTAButton';
import { TTranslator } from 'hooks/use-translation';
import { DefaultTheme, FlattenInterpolation, ThemeProps } from 'styled-components';
import { TEXT_LABEL_L_BOLD, TEXT_LABEL_M_BOLD, TEXT_LABEL_S_BOLD, TEXT_TITLE_L } from 'style/design-system/textStyles';

const CTA_BEHAVIORS = {
  goToPage: 'goToPage',
  openLink: 'openLink',
  openLoginModal: 'openLoginModal',
  openCreateAccountModal: 'openCreateAccountModal',
} as const;

export type CTABehavior = ObjectValues<typeof CTA_BEHAVIORS>;

export const getCtaBehaviorDropdownOptions = (t: TTranslator): { iconName: string; label: string, value: CTABehavior; }[] => [
  {
    label: t('GO_TO_PAGE'),
    value: 'goToPage',
    iconName: 'pageOutline',
  },
  {
    label: t('ADMIN_ACTION_OPEN_LINK'),
    value: 'openLink',
    iconName: 'linkOutline',
  },
  {
    label: t('OPEN_LOGIN_MODAL'),
    value: 'openLoginModal',
    iconName: 'singleUser',
  },
  {
    label: t('OPEN_CREATE_ACCOUNT_MODAL'),
    value: 'openCreateAccountModal',
    iconName: 'userPlus',
  },
];

export type GoToPageBehaviorData = {
  pageId: string;
  type: 'goToPage';
};

export type OpenLinkBehaviorData = {
  openInNewTab: boolean;
  type: 'openLink';
  url: string;
};

export type OpenLoginModalBehaviorData = {
  type: 'openLoginModal';
};

export type OpenCreateAccountModalBehaviorData = {
  type: 'openCreateAccountModal';
};

export type CtaBehaviorData = GoToPageBehaviorData | OpenLinkBehaviorData | OpenLoginModalBehaviorData | OpenCreateAccountModalBehaviorData;

export const CTA_BEHAVIOR_DEFAULT_DATA: Record<CTABehavior, CtaBehaviorData> = {
  goToPage: {
    pageId: '',
    type: 'goToPage',
  },
  openCreateAccountModal: {
    type: 'openCreateAccountModal',
  },
  openLink: {
    url: '',
    openInNewTab: true,
    type: 'openLink',
  },
  openLoginModal: {
    type: 'openLoginModal',
  },
};

export const DEFAULT_CTA_BEHAVIOR = CTA_BEHAVIOR_DEFAULT_DATA.goToPage;

export const CTA_SIZES_HEIGHT_PX_MAP: Record<CTASize, `${number}px`> = {
  XXL: '64px',
  XL: '52px',
  L: '44px',
  M: '40px',
  S: '32px',
  XS: '24px',
};

export const CTA_SIZES_INLINE_PADDING_MAP: Record<CTASize, `${number}px`> = {
  XXL: '54px',
  XL: '36px',
  L: '36px',
  M: '36px',
  S: '20px',
  XS: '18px',
};

export const CTA_SIZES_DESIGN_TOKENS_MAP: Record<CTASize, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
  XXL: TEXT_TITLE_L,
  XL: TEXT_LABEL_L_BOLD,
  L: TEXT_LABEL_L_BOLD,
  M: TEXT_LABEL_M_BOLD,
  S: TEXT_LABEL_S_BOLD,
  XS: TEXT_LABEL_S_BOLD,
};

export const CTA_SIZES_FONT_SIZE_MAP: Record<CTASize, number> = {
  XXL: 24,
  XL: 16,
  L: 16,
  M: 14,
  S: 12,
  XS: 12,
};

const CTA_SIZES = {
  XXL: 'XXL',
  XL: 'XL',
  L: 'L',
  M: 'M',
  S: 'S',
  XS: 'XS',
} as const;

export const DEFAULT_CTA_HEIGHT_SIZE = CTA_SIZES.XL;

export type CTASize = ObjectValues<typeof CTA_SIZES>;

export type CTAButtonProps = {
  /**
   * The behavior of the CTA button.
   * Options like open link, go to page, open login modal, etc.
   */
  behavior: CtaBehaviorData;
  /**
   * Only used for desktop. Mobile uses the CTASize to map the design tokens.
   */
  fontSize?: number;
  /**
   * The height of the CTA button.
   * This is also used to map the design tokens for the text styles and inline padding.
   */
  height: {
    desktop: CTASize;
    mobile: CTASize;
  }
  text: string;
  variant: CTAVariant;
};

export const isOpenLinkBehavior = (behavior: any): behavior is OpenLinkBehaviorData => {
  return behavior.type === 'openLink';
};

export const isGoToPageBehavior = (behavior: any): behavior is GoToPageBehaviorData => {
  return behavior.type === 'goToPage';
};

export const isOpenLoginModalBehavior = (behavior: any): behavior is OpenLoginModalBehaviorData => {
  return behavior.type === 'openLoginModal';
};

export const isOpenCreateAccountModalBehavior = (behavior: any): behavior is OpenCreateAccountModalBehaviorData => {
  return behavior.type === 'openCreateAccountModal';
};

export const FONT_SIZES = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 30, 36, 40, 44, 48, 60, 72, 96].map(n => ({
  label: n.toString(),
  value: n,
}));

export const sizesDropdownOptions = Object.keys(CTA_SIZES).map<{ label: string; value: CTASize; }>((size) => ({
  label: `${size} (${CTA_SIZES_HEIGHT_PX_MAP[size]})`,
  value: size as CTASize,
}));
