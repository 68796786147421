import React, { MouseEventHandler } from 'react';
import withConfirmation from 'components/core/withConfirmation';
import withRenderCondition from 'components/core/withRenderCondition';
import { useSelector, useDispatch } from 'react-redux';
import { SuccessLabel } from 'components/ui/Label';
import {
  hasUnpublishedAdminChanges,
  getToolbarPendingChanges,
  getPendingPageDoc,
} from 'services/admin/selectors';
import { replace } from 'services/app-router/actions';
import { AutoSaveContainer } from '../styles';
import Button from './Button';
import useHandlers from '../ToolbarView/Default/hooks/use-handlers';
import { getAdminSurface5 } from 'services/themes';
import { getAdminText100 } from 'services/themes';

interface AutoSaveProps {
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const DiscardButton = withConfirmation(Button);

function AutoSave({ className, onClick }: AutoSaveProps) {
  const dispatch = useDispatch();
  const hasAdminChanges = useSelector(hasUnpublishedAdminChanges);
  const adminThemeSurface5 = useSelector(getAdminSurface5);
  const toolbarPendingChanges = useSelector(getToolbarPendingChanges);
  const pendingPageDoc = useSelector(getPendingPageDoc);
  const adminText100 = useSelector(getAdminText100);
  const { clearPendingChanges } = useHandlers();

  const hasEditorChanges = hasAdminChanges || !!toolbarPendingChanges;

  if (!hasEditorChanges) {
    return null;
  }

  const discardChanges = () => {
    const isNotPublished = Boolean(pendingPageDoc && !pendingPageDoc.created);
    clearPendingChanges();
    if (isNotPublished) {
      dispatch(replace({ path: '/' }));
    }
  };

  return (
    <AutoSaveContainer className={className}>
      <SuccessLabel
        data-testid="changesSavedLabel"
        color={adminText100}
        compact={true}
        iconRightAlign={true}
        iconSize={14.61}
        iconSpacing={12}
        labelKey="ADMIN_LABEL_CHANGES_SAVED"
      />
      <DiscardButton
        data-testid="discardChangesButton"
        background={adminThemeSurface5}
        descriptionTextKey="ADMIN_LABEL_UNDO_WARNING"
        hoverBackground={adminThemeSurface5}
        action={onClick || discardChanges}
        shouldAskConfirmation={true}
        stringKey="ADMIN_LABEL_DISCARD"
        messageKey="ADMIN_LABEL_DISCARD_CHANGES"
        minWidth="93px"
      />
    </AutoSaveContainer>
  );
}

export default withRenderCondition(AutoSave);
