import React, { useState, useRef } from 'react';
import { FileWithPreview } from 'react-dropzone';
import { UploadLabel, UploadLableError, StyledDropzone, StyledIcon, WarningIcon } from './styles';
import TranslatedText from 'components/i18n/TranslatedText';

export interface IVideoUploaderZoneProps {
  className?: string;
  disabled?: boolean;
  maxSize?: number;
  onDrop: (files: FileWithPreview[], status: 'accepted' | 'rejected') => void;
}

const VideoUploaderZone: React.FC<IVideoUploaderZoneProps> = ({
  className = '',
  disabled = false,
  maxSize = 10737418240, // 10 GB in bytes
  onDrop,
}) => {
  const dropzoneRef = useRef<any>(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [isRejected, setIsRejected] = useState(false);

  const handleOnDragEnter = () => {
    if (!isDragOver) {
      setIsDragOver(true);
    }
  };

  const handleOnDragLeave = () => {
    if (isDragOver) {
      setIsDragOver(false);
    }
  };

  const openFileDialog = () => {
    dropzoneRef.current?.open?.();
  };

  const onHandleDrop = (
    accepted: FileWithPreview[],
    rejected: FileWithPreview[],
  ) => {
    if (onDrop) {
      const files = accepted || rejected;
      const status = accepted.length > 0 ? 'accepted' : 'rejected';
      onDrop(files, status);
      setIsDragOver(false);
      setIsRejected(status === 'rejected');
    }
  };

  const renderDropzone = () => {
    if (isDragOver) {
      return (
        <>
          <StyledIcon name="dragAndDropVideo" />
          <UploadLabel>
            <TranslatedText stringKey="VIDEO_LIBRARY_DROP_ZONE_DRAG_LABEL" />
          </UploadLabel>
        </>
      );
    }
    if (isRejected) {
      return (
        <>
          <StyledIcon name="dragAndDropVideoError" />
          <UploadLableError>
            <WarningIcon />
            <TranslatedText stringKey="VIDEO_LIBRARY_DROP_ZONE_DRAG_ERROR" />
          </UploadLableError>
        </>
      );
    }

    return (
      <>
        <StyledIcon name="dragAndDropVideo" />
        <UploadLabel>
          <TranslatedText stringKey="VIDEO_LIBRARY_DROP_ZONE_DROP_MP4_FILE_LABEL" />{' '}
          <button onClick={openFileDialog}>
            <TranslatedText stringKey="VIDEO_LIBRARY_DROP_ZONE_DROP_MP4_CLICK_SELECT" />
          </button>{' '}
          <TranslatedText stringKey="VIDEO_LIBRARY_DROP_ZONE_DROP_MP4_DEVICE_LABEL" />
        </UploadLabel>
      </>
    );
  };

  return (
    <StyledDropzone
      data-testid="videoUploaderZone"
      ref={dropzoneRef}
      className={className}
      disabled={disabled}
      disableClick={true}
      isDragOver={isDragOver}
      onDragEnter={handleOnDragEnter}
      onDragLeave={handleOnDragLeave}
      maxSize={maxSize}
      onDrop={onHandleDrop}
      accept="video/mp4"
    >
      {renderDropzone()}
    </StyledDropzone>
  );
};

export default VideoUploaderZone;
