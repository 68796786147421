/* tslint:disable:jsx-boolean-value */
import React, { useMemo } from 'react';
import { ControlsProps } from 'components/Video/video-controls/VideoOverlay/interfaces';
import { SidebarIcon } from 'components/ui/Button';
import { PlayerTypes } from 'models';
import { SIDEBAR_TOGGLE_ICON } from 'injection-classes';

export default function SidebarToggleButton({
  isFullscreen,
  isMobileLayout,
  panelState,
  trackVideo,
  updateWantsSidebar,
  video,
  videoKind = PlayerTypes.file,
  videoTitle = '',
  spot,
}: ControlsProps) {
  const sidebarVisible = useMemo(() => {
    return panelState.state === 'showing';
  }, [panelState.state]);

  const toggleSidebar = (open: boolean) => {
    const url = video?.url || '';

    updateWantsSidebar(open);

    if (video) {
      trackVideo(
        'control',
        { id: video._id, kind: 'sidebar', spot, title: videoTitle },
        {
          action: open ? 'open' : 'close',
          kind: videoKind,
          url,
        },
      );
    }
  };

  const showSidebar = () => toggleSidebar(true);
  const hideSidebar = () => toggleSidebar(false);

  if (isMobileLayout || panelState.state === 'disabled' || isFullscreen) {
    return null;
  }

  return (
    <SidebarIcon
      className={SIDEBAR_TOGGLE_ICON}
      admin
      name={`${sidebarVisible ? 'hide' : 'show'}Sidebar`}
      onClick={panelState.state === 'hiding' ? showSidebar : hideSidebar}
      tooltipAlign="left"
      tooltipKey={`ACTION_${sidebarVisible ? 'CLOSE' : 'OPEN'}_SIDEBAR`}
    />
  );
}
