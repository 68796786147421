import React, { FormEvent, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  NAVIGATION_BLOCK_EXTERNAL_LINKS_INPUTS_ID,
  NAVIGATION_BLOCK_CUSTOM_NAVIGATION_CREATE_LINK_NAME_ID,
  NAVIGATION_BLOCK_CUSTOM_NAVIGATION_CREATE_LINK_TRASHCAN_ID,
  NAVIGATION_BLOCK_CUSTOM_NAVIGATION_CREATE_LINK_CHECKMARK_ID,
  NAVIGATION_BLOCK_CUSTOM_NAVIGATION_CREATE_LINK_URL_ID,
} from 'global-ids';
import { addLinkToNavigation } from 'services/navigationv2';
import { LinkIcon, CheckmarkIcon, TrashIcon, WarningIcon } from '../SiteNavigationExternalLinksForm/icons';
import { ErrorSection, ErrorText, InputsSection, LinkNameInputContainer, LinkURLInput, LinkURLInputContainer, LinkURLLabel, LinkURLRow, NameInput } from './styles';
import { useAdminTranslation } from 'hooks/use-translation';
import { generateRandomHexID } from 'utils';
import { NAV_ITEM_NAME_MAX_LENGTH } from 'services/navigationv2/utils';
import { HTTPS_PREFIX } from 'hooks/use-link-input-validation';

export const EXTERNAL_LINK_NAME_MAX_LENGTH = NAV_ITEM_NAME_MAX_LENGTH;

interface IExternalLinksInputs {
  closeItself: () => void;
  handleLinkUrlChange: (value: string) => void;
  isSaveButtonDisabled: boolean;
  linkName: string;
  linkUrl: string;
  linkUrlStatus: 'typing' | 'valid' | 'invalid';
  navigationId: string;
  setLinkName: (value: string) => void;
}

const ExternalLinksInputs: React.FC<IExternalLinksInputs> = ({
  closeItself,
  handleLinkUrlChange,
  isSaveButtonDisabled,
  linkName,
  linkUrl,
  linkUrlStatus,
  navigationId,
  setLinkName,
}) => {
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSave = useCallback(() => {
    if (isSaveButtonDisabled) return;

    dispatch(addLinkToNavigation(navigationId, {
      id: generateRandomHexID() ,
      name: linkName,
      url: `${HTTPS_PREFIX}${linkUrl}`,
    }));

    setLinkName('');
    handleLinkUrlChange('');
    closeItself();
  }, [isSaveButtonDisabled, linkName, linkUrl, navigationId, closeItself, dispatch]);

  const handleNameChange = useCallback((value: string) => {
    setLinkName(value.slice(0, EXTERNAL_LINK_NAME_MAX_LENGTH));
  }, [setLinkName]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleSave();
  };

  React.useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <InputsSection
      id={NAVIGATION_BLOCK_EXTERNAL_LINKS_INPUTS_ID}
      data-testid={NAVIGATION_BLOCK_EXTERNAL_LINKS_INPUTS_ID}
      onSubmit={handleSubmit}
    >
      <LinkNameInputContainer>
        <LinkIcon />
        <NameInput
          setInputRef={inputRef}
          onChange={handleNameChange}
          value={linkName}
          placeholderKey="ADMIN_NAVIGATION_BLOCK_EXTERNAL_LINKS_INPUT_NAME_PLACEHOLDER"
          data-testid={NAVIGATION_BLOCK_CUSTOM_NAVIGATION_CREATE_LINK_NAME_ID}
        />
        <TrashIcon tabIndex={-1} onClick={closeItself} data-testid={NAVIGATION_BLOCK_CUSTOM_NAVIGATION_CREATE_LINK_TRASHCAN_ID}/>
        <button type="submit" tabIndex={-1}>
          <CheckmarkIcon
            tabIndex={-1}
            data-testid={NAVIGATION_BLOCK_CUSTOM_NAVIGATION_CREATE_LINK_CHECKMARK_ID}
          />
        </button>
      </LinkNameInputContainer>
      <LinkURLInputContainer>
        <LinkURLRow>
          <LinkURLLabel
            linkUrlStatus={linkUrlStatus}
            shouldShow={!!linkUrl}
          >
            {HTTPS_PREFIX}
          </LinkURLLabel>
          <LinkURLInput
            linkUrlStatus={linkUrlStatus}
            onChange={handleLinkUrlChange}
            value={linkUrl}
            placeholderKey="ADMIN_NAVIGATION_BLOCK_EXTERNAL_LINKS_INPUT_URL_PLACEHOLDER"
            data-testid={NAVIGATION_BLOCK_CUSTOM_NAVIGATION_CREATE_LINK_URL_ID}
          />
        </LinkURLRow>
        {linkUrlStatus === 'invalid' && (
          <ErrorSection>
            <WarningIcon />
            <ErrorText>{t('ADMIN_NAVIGATION_BLOCK_EXTERNAL_LINKS_INPUT_ERROR_MESSAGE')}</ErrorText>
          </ErrorSection>
        )}
      </LinkURLInputContainer>
    </InputsSection>
  );
};

export default ExternalLinksInputs;
