import { connect } from 'react-redux';

import withErrorGuard from 'components/core/withErrorGuard';
import { getObjectRendererDraft, getMockCardRenderer } from 'services/admin';
import { isLoggedIn } from 'services/auth';
import { trackCard } from 'services/insights';
import {
  CARD_COLLAPSED,
  CARD_DISMISSED,
  CARD_OPEN,
  getCardKey,
  getCardState,
  setCardState,
} from 'services/card';
import { openPanel } from 'services/custom-panels';
import { showModal } from 'services/modals';
import CardView from './view';

export { default as CardView, CARD_VIEW_PREVIEW_PROPS } from './view';

const mapStateToProps = (state, ownProps) => {
  const cardKey = getCardKey(state, ownProps.doc);
  const currentState = getCardState(state, cardKey);
  const id = ownProps.doc?._id;
  return {
    cardKey,
    isCollapsed: currentState === CARD_COLLAPSED,
    isDismissed: currentState === CARD_DISMISSED,
    loggedIn: isLoggedIn(state),
    mockCardRenderer: getMockCardRenderer(state),
    rendererDraft: id ? getObjectRendererDraft(state, 'card', id) : undefined,
  };
};

const mapDispatchToProps = dispatch => ({
  onCollapse: cardKey => dispatch(setCardState(cardKey, CARD_COLLAPSED)),
  onDismiss: (cardKey, renderer) => {
    dispatch(setCardState(cardKey, CARD_DISMISSED));
    dispatch(trackCard('dismiss', renderer));
  },
  onOpen: cardKey => dispatch(setCardState(cardKey, CARD_OPEN)),
  openPanel: (...args) => dispatch(openPanel(...args)),
  showModal: (...args) => dispatch(showModal(...args)),
  trackCard: (...args) => dispatch(trackCard(...args)),
});

export default withErrorGuard()(
  connect(mapStateToProps, mapDispatchToProps)(CardView),
);
