import React from 'react';
import PropTypes from 'prop-types';

import RendererEditor from 'components/admin2/RendererEditor';

import {
  ChannelSelectEditor,
  ChatPanelEditor,
  LeaderboardPanelEditor,
  SocialPanelEditor,
  PeoplePanelEditor,
  ShopifyPanelEditor,
  TippingPanelEditor,
  ShopifyBlockPanelEditor,
} from 'components/admin2/editorPanels';

import {
  CHANNEL_SELECT_ID,
  SOCIAL_ID,
  CHAT_ID,
  PEOPLE_ID,
  IFRAME_ID,
  IMAGE_V2_ID,
  SCHEDULE_ID,
  PLAYLIST_ID,
  TWITCH_CHAT_ID,
  LEADERBOARD_ID,
  MULTIPLE_CHOICE_ID,
  QUEST_ID,
  PLAY_BY_PLAY_ID,
  FORTNITE_DUOS_MATCH_ID,
  FORTNITE_SINGLES_MATCH_ID,
  FORTNITE_SINGLES_LEADERBOARD_ID,
  FORTNITE_TEAMS_LEADERBOARD_ID,
  FORTNITE_PLAY_BY_PLAY_ID,
  PUBG_MATCH_ID,
  SHOPIFY_BLOCK_PANEL_ID,
  SHOPIFY_ID,
  TIPS_ID,
} from 'components/objects/PanelV2/constants';

import { RendererWrapper } from './styles';

const KIND_MAP = {
  [CHANNEL_SELECT_ID]: ChannelSelectEditor,
  [CHAT_ID]: ChatPanelEditor,
  [LEADERBOARD_ID]: LeaderboardPanelEditor,
  [PEOPLE_ID]: PeoplePanelEditor,
  [SHOPIFY_BLOCK_PANEL_ID]: ShopifyBlockPanelEditor,
  [SHOPIFY_ID]: ShopifyPanelEditor,
  [SOCIAL_ID]: SocialPanelEditor,
  [TIPS_ID]: TippingPanelEditor,
};

const SOCIAL_SOURCE_KEY = 'source';
const SCHEDULE_EVENT_NAME_KEY = 'name';

const iconAndNameFields = [
  {
    fieldName: 'iconName',
    type: 'icon',
    width: '35%',
  },
  {
    fieldName: 'panelName',
    labelKey: 'ADMIN_LABEL_NAME',
    type: 'string',
    width: '60%',
  },
];

const imageListField = {
  fieldName: 'imageList',
  type: 'array',
  typeOptions: {
    addItemKey: 'ADMIN_LABEL_ADD_IMAGE',
    collapsible: true,
    deletable: true,
    emptyListKey: 'ADMIN_LABEL_NO_IMAGES',
    emptyTitleKey: 'ADMIN_LABEL_NEW_IMAGE',
    itemConfig: [
      {
        fieldName: 'imageName',
        labelKey: 'NAME',
        type: 'string',
      },
      {
        fieldName: 'imageUrl',
        labelKey: 'ADMIN_LABEL_IMAGE',
        type: 'image',
        typeOptions: {
          dimensionRequirements: {
            exact: false,
            pixelHeight: 1000,
            pixelWidth: 320,
            verify: false,
          },
        },
      },
      {
        fieldName: 'linkUrl',
        labelKey: 'ADMIN_LABEL_URL',
        type: 'string',
      },
    ],
    itemIcon: 'pictures',
    itemSubtitleKey: 'ADMIN_LABEL_IMAGE',
    itemTitleField: 'imageName',
  },
};

const idsToFields = {
  [FORTNITE_DUOS_MATCH_ID]: [
    ...iconAndNameFields,
    {
      fieldName: 'tournamentId',
      labelKey: 'ADMIN_LABEL_TOURNAMENT_ID',
      type: 'string',
    },
  ],
  [FORTNITE_PLAY_BY_PLAY_ID]: [
    ...iconAndNameFields,
    {
      fieldName: 'matchId',
      labelKey: 'ADMIN_LABEL_MATCH_ID',
      type: 'string',
    },
  ],
  [FORTNITE_SINGLES_LEADERBOARD_ID]: [
    ...iconAndNameFields,
    {
      fieldName: 'eventId',
      labelKey: 'ADMIN_LABEL_EVENT_ID',
      type: 'string',
    },
    {
      fieldName: 'eventWindowId',
      labelKey: 'ADMIN_LABEL_EVENT_WINDOW_ID',
      type: 'string',
    },
  ],
  [FORTNITE_SINGLES_MATCH_ID]: [
    ...iconAndNameFields,
    {
      fieldName: 'tournamentId',
      labelKey: 'ADMIN_LABEL_TOURNAMENT_ID',
      type: 'string',
    },
  ],
  [FORTNITE_TEAMS_LEADERBOARD_ID]: [
    ...iconAndNameFields,
    {
      fieldName: 'eventId',
      labelKey: 'ADMIN_LABEL_EVENT_ID',
      type: 'string',
    },
    {
      fieldName: 'eventWindowId',
      labelKey: 'ADMIN_LABEL_EVENT_WINDOW_ID',
      type: 'string',
    },
  ],
  [IFRAME_ID]: [
    ...iconAndNameFields,
    {
      fieldName: 'url',
      labelKey: 'ADMIN_LABEL_URL',
      type: 'string',
    },
    {
      fieldName: 'iframeTag',
      labelKey: 'ADMIN_LABEL_IFRAME_TAG',
      type: 'string',
    },
    {
      fieldName: 'disableOnMobileEmbedded',
      labelKey: 'DISABLE_ON_MOBILE_EMBEDDED',
      type: 'boolean',
    },
  ],
  [IMAGE_V2_ID]: [
    ...iconAndNameFields,
    {
      fieldName: 'view',
      labelKey: 'ADMIN_LABEL_IMAGE_VIEW_TYPE',
      type: 'switch',
      typeOptions: {
        defaultId: 'list',
        optional: false,
        style: 'buttons',
        variantField: 'type',
        variants: [
          {
            icon: 'list-bullet',
            id: 'list',
            labelKey: 'ADMIN_LABEL_LIST',
          },
          {
            icon: 'adminbarChannels',
            id: 'grid',
            labelKey: 'ADMIN_LABEL_GRID',
          },
        ],
      },
    },
    imageListField,
  ],
  [MULTIPLE_CHOICE_ID]: [
    ...iconAndNameFields,
    {
      fieldName: 'description',
      labelKey: 'ADMIN_LABEL_DESCRIPTION',
      type: 'html',
      typeOptions: {
        helperTextKey: 'ADMIN_LABEL_DESCRIPTION_HELPER',
      },
    },
    {
      fieldName: 'loginButtonText',
      labelKey: 'ADMIN_LABEL_LOGIN_BUTTON_TEXT',
      type: 'string',
    },
    {
      fieldName: 'questions',
      labelKey: 'ADMIN_LABEL_QUESTIONS',
      type: 'pollIds',
    },
  ],
  [PLAY_BY_PLAY_ID]: [
    ...iconAndNameFields,
    {
      fieldName: 'gameId',
      labelKey: 'ADMIN_LABEL_GAME_ID',
      type: 'firebaseKey',
    },
  ],
  [PLAYLIST_ID]: [
    ...iconAndNameFields,
    {
      fieldName: 'playlistId',
      labelKey: 'ADMIN_PANEL_PLAYLIST_NAME',
      type: 'playlistId',
    },
    {
      fieldName: 'layout',
      labelKey: 'ADMIN_LABEL_LAYOUT',
      type: 'radio',
      typeOptions: {
        choices: [
          {
            id: 'single',
            text: 'ADMIN_LABEL_SINGLE_COLUMN',
          },
          {
            id: 'double',
            text: 'ADMIN_LABEL_DOUBLE_COLUMN',
          },
        ],
        default: 'single',
        name: 'playlist-layout',
      },
    },
    {
      fieldName: 'displayIcon',
      labelKey: 'ADMIN_LABEL_DISPLAY_ICON',
      type: 'boolean',
      typeOptions: {
        default: true,
      },
      width: '200px',
    },
    {
      fieldName: 'icon',
      type: 'radio',
      typeOptions: {
        choices: [
          {
            id: 'playLarge',
            text: 'ADMIN_LABEL_PLAY_ICON',
          },
          {
            id: 'playlistCamera',
            text: 'ADMIN_LABEL_CAMERA_ICON',
          },
        ],
        default: 'playLarge',
        name: 'playlist-display-icon',
      },
    },
    {
      fieldName: 'displayDuration',
      labelKey: 'ADMIN_LABEL_DISPLAY_DURATION',
      type: 'boolean',
      typeOptions: {
        default: true,
      },
      width: '200px',
    },
  ],
  [PUBG_MATCH_ID]: [
    ...iconAndNameFields,
    {
      fieldName: 'tournamentId',
      labelKey: 'ADMIN_LABEL_TOURNAMENT_ID',
      type: 'string',
    },
  ],
  [QUEST_ID]: [
    ...iconAndNameFields,
    {
      fieldName: 'rulesLinkUrl',
      labelKey: 'ADMIN_LABEL_RULES_LINK',
      type: 'string',
    },
    {
      fieldName: 'helpLinkUrl',
      labelKey: 'ADMIN_LABEL_HELP_LINK',
      type: 'string',
    },
    {
      fieldName: 'loginButtonText',
      labelKey: 'ADMIN_LABEL_LOGIN_BUTTON_TEXT',
      type: 'string',
    },
    {
      fieldName: 'showCompletedCount',
      labelKey: 'ADMIN_LABEL_SHOW_COMPLETED_COUNT',
      type: 'toggle',
      typeOptions: {
        helperTextKey: 'ADMIN_DESC_INDIVIDUAL_QUEST',
      },
    },
    {
      fieldName: 'description',
      labelKey: 'ADMIN_LABEL_DESCRIPTION',
      type: 'string',
      typeOptions: {
        helperTextKey: 'ADMIN_LABEL_DESCRIPTION_HELPER',
      },
    },
    {
      fieldName: 'quests',
      labelKey: 'ADMIN_LABEL_QUESTS',
      type: 'questIds',
    },
  ],
  [SCHEDULE_ID]: [
    ...iconAndNameFields,
    {
      fieldName: 'description',
      labelKey: 'ADMIN_LABEL_DESCRIPTION',
      type: 'text',
    },
    {
      fieldName: 'groupByDate',
      labelKey: 'ADMIN_LABEL_GROUP_DATE',
      type: 'boolean',
      typeOptions: {},
    },
    {
      fieldName: 'scheduleTypeRenderer',
      labelKey: 'ADMIN_LABEL_SCHEDULE_TYPE',
      type: 'switch',
      typeOptions: {
        defaultId: 'manual',
        optional: false,
        style: 'buttons',
        variantField: 'scheduleType',
        variants: [
          {
            icon: 'adminbarChannels',
            id: 'calendar',
            labelKey: 'ADMIN_LABEL_CHANNELS',
            properties: [{
              fieldName: 'sourceChannels',
              type: 'pageToggle',
            }],
          },
          {
            icon: 'adminbarEditor',
            id: 'manual',
            labelKey: 'ADMIN_LABEL_MANUAL',
            properties: [{
              fieldName: 'manualEvents',
              type: 'array',
              typeOptions: {
                addItemKey: 'ADMIN_LABEL_ADD_EVENT',
                collapsible: true,
                deletable: true,
                deleteButtonKey: 'ACTION_DELETE_EVENT',
                emptyListKey: 'ADMIN_LABEL_NO_EVENTS',
                emptyTitleKey: 'ADMIN_LABEL_NEW_EVENT',
                itemConfig: [
                  {
                    fieldName: SCHEDULE_EVENT_NAME_KEY,
                    labelKey: 'ADMIN_LABEL_NAME',
                    type: 'string',
                  },
                  {
                    fieldName: 'schedule',
                    labelKey: 'ADMIN_LABEL_SCHEDULE',
                    type: 'schedule',
                    typeOptions: {
                      required: true,
                    },
                  },
                  {
                    fieldName: 'location',
                    labelIcon: 'location',
                    labelKey: 'ADMIN_LABEL_LOCATION',
                    type: 'string',
                  },
                  {
                    fieldName: 'moreInfo',
                    labelKey: 'ADMIN_LABEL_MORE_INFO',
                    type: 'text',
                  },
                  {
                    type: 'divider',
                    typeOptions: {
                      spacing: 15,
                    },
                  },
                  {
                    fieldName: 'references',
                    labelKey: 'ADMIN_LABEL_REFERENCE_SOURCE',
                    type: 'personOrGroupIds',
                    typeOptions: {
                      limit: 2,
                    },
                  },
                ],
                itemIcon: 'calendar',
                itemSubtitle: (item) => {
                  const { startTime, endTime } = item;
                  const startDate = (startTime ? new Date(startTime) : new Date())
                    .toLocaleDateString();
                  const endDate = (endTime ? new Date(endTime) : new Date())
                    .toLocaleDateString();
                  return `${startDate} - ${endDate}`;
                },
                itemTitleField: SCHEDULE_EVENT_NAME_KEY,
                noPadding: true,
              },
            }],
          },
        ],
      },
    },
  ],
  [SOCIAL_ID]: [
    ...iconAndNameFields,
    {
      fieldName: 'sources',
      labelKey: 'ADMIN_LABEL_TWITTER',
      type: 'array',
      typeOptions: {
        addItemKey: 'ADMIN_ACTION_ADD_SOURCE',
        collapsible: true,
        deletable: true,
        emptyListKey: 'ADMIN_LABEL_NO_SOURCES',
        emptyTitleKey: 'ADMIN_LABEL_NEW_SOURCE',
        itemConfig: [
          {
            fieldName: SOCIAL_SOURCE_KEY,
            labelKey: 'ADMIN_LABEL_SOURCE',
            placeholderLabelKey: 'ADMIN_LABEL_TWITTER_SOURCE_PLACEHOLDER',
            type: 'string',
          },
        ],
        itemIcon: 'twitter',
        itemSubtitleKey: 'SOCIAL_LABEL',
        itemTitleField: SOCIAL_SOURCE_KEY,
      },
    },
  ],
  [TWITCH_CHAT_ID]: [
    ...iconAndNameFields,
    {
      fieldName: 'channel',
      labelKey: 'ADMIN_LABEL_CHANNEL',
      type: 'string',
    },
    {
      fieldName: 'enableDarkTheme',
      labelKey: 'ADMIN_LABEL_TWITCH_CHAT_DARK',
      type: 'boolean',
    },
  ],
};

export default class PanelEditor extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    panelType: PropTypes.string.isRequired,
    renderer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  render() {
    const {
      panelType,
      onChange,
      renderer,
    } = this.props;

    // TODO figure out how to use renderer here for editing maybe

    const properties = idsToFields[panelType];

    if (properties) {
      return (
        <RendererWrapper>
          <RendererEditor
            onChange={onChange}
            properties={properties}
            renderer={renderer}
          />
        </RendererWrapper>
      );
    }

    // If the schema for the id isn't defined, see if it is implemented in
    // the KIND_MAP
    if (KIND_MAP[panelType]) {
      const Component = KIND_MAP[panelType];
      return (
        <RendererWrapper>
          <Component
            onChange={onChange}
            renderer={renderer}
          />
        </RendererWrapper>
      );
    }
    // eslint-disable-next-line no-console
    console.warn('Panel Editor: No renderer or override found.');
    return null;
  }
}
