import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import hash from 'json-stable-stringify';
import RealtimeDocument from 'components/core/RealtimeDocument';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import { useDispatch, useSelector } from 'hooks';
import { getUserId } from 'services/auth';
import {
  getProgress,
  getQuests,
  initializeAllQuestProgress,
  resetAllQuestProgress,
  updateQuestProgress,
} from 'services/quest';

const makeEmptyQuestProgress = quests => Object.entries(quests).reduce((acc, [id, quest]) => {
  const actions = quest.actions || [];
  const actionProgress = actions.reduce((all, a) => ({
    ...all,
    [a.arrayId]: { progress: 0 },
  }), {});
  return {
    ...acc,
    [id]: {
      actions: actionProgress,
      completed: false,
    },
  };
}, {});

// set up firebase progress listeners for all active quests
export default function QuestProgressFetcher() {
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const quests = useSelector(getQuests);
  const allProgress = useSelector(getProgress);
  const [valueCache, setCachedValues] = useState({});

  // clear all progress on logout
  useEffect(
    () => {
      if (!userId) {
        dispatch(resetAllQuestProgress());
        setCachedValues({});
      }
    },
    [userId],
  );

  // initialize empty progress for a new quest or
  // before existing progress value is received
  useEffect(
    () => {
      const allInitialized = Object.keys(quests).every(questId => allProgress[questId]);
      if (!isEmpty(quests) && !allInitialized) {
        const emptyProgress = makeEmptyQuestProgress(quests);
        dispatch(initializeAllQuestProgress(emptyProgress));
      }
    },
    [hash(quests)],
  );

  // lazy load individual quest progress from firebase into redux
  const onValue = (questId, value, loaded) => {
    const progress = loaded ? value : null;
    const currentProgress = JSON.stringify(progress);

    if (progress && valueCache[questId] !== currentProgress) {
      setCachedValues({
        ...valueCache,
        [questId]: currentProgress,
      });
      dispatch(updateQuestProgress({ progress, questId }));
    }
  };

  if (!userId) {
    return null;
  }

  return (
    <NonKeyedListMapper list={Object.keys(quests)}>
      {
        (key, questId) => (
          <RealtimeDocument
            key={key}
            collection="user_quest_progress_view_v2"
            id={`${userId}:${questId}`}
            onValue={(value, loaded) => onValue(questId, value, loaded)}
          />
        )
      }
    </NonKeyedListMapper>
  );
}
