import React, { PropsWithChildren } from 'react';
import ErrorDisplay from 'components/ui/ErrorDisplay';
import { ThemeProvider as StyledComponentThemeProvider } from 'styled-components';
import SiteFontLoader from '../SiteFontLoader';
import RealtimeSite from 'components/core/RealtimeSite';
import DocumentTitle from 'components/application/Site/DocumentTitle';
import ErrorBoundary from 'components/core/ErrorBoundary';
import ScreenOrientation from './ScreenOrientation';
import { useSelector } from 'react-redux';
import { Background } from './styles';
import SiteWrapper from '../SiteWrapper';
import ModalContainer from 'components/modals/ModalContainer';
import AdminFontLoader from 'components/application/SiteFontLoader/AdminFontLoader';
import ConfirmationModal from 'components/admin2/ui/ConfirmationModal';
import TazerDetection from 'components/insights/TazerDetection';
import { ToastsContainer } from 'components/Toast';
import { ModalPortalContainer } from 'components/modals/ModalPortal';
import { getStyledComponentsThemeProvider, isPageVisible as getIsPageVisible } from 'services/app/selectors';
import GlobalStyles from './GlobalStyles';

const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const theme = useSelector(getStyledComponentsThemeProvider);
  return (
    <StyledComponentThemeProvider theme={theme}>
      {children}
    </StyledComponentThemeProvider>
  );
};

const App = () => {
  const isPageVisible = useSelector(getIsPageVisible);

  const renderCrashScreen = React.useCallback(({
    error,
    errorInfo,
  }) => (
    <ErrorDisplay
      error={error}
      errorInfo={errorInfo}
      errorNameKey="SYSTEM_ERROR"
      errorSubtitleKey="CRASH_PAGE_SUBTITLE"
      errorTitleKey="CRASH_PAGE_TITLE"
      iconName="crashPage"
      includeReloadButton={true}
    />
  ), []);

  const renderNotFoundPage = React.useCallback(() => (
    <>
      <DocumentTitle title="404 Page Not Found" />
      <ErrorDisplay
        errorNameKey="404_ERROR"
        errorSubtitleKey="NOT_FOUND_PAGE_BODY"
        errorTitleKey="NOT_FOUND_PAGE_TITLE"
        iconName="notFoundPage"
      />
    </>
  ), []);

  return (
    <ThemeProvider>
      <GlobalStyles />
      <SiteFontLoader />
      <RealtimeSite />
      {isPageVisible ? (
        <ErrorBoundary fallback={renderCrashScreen}>
          <ScreenOrientation />
          <Background>
            <SiteWrapper />
            <ModalContainer />
            <ConfirmationModal />
            <TazerDetection />
            <ToastsContainer />
            <ModalPortalContainer />
            <AdminFontLoader />
          </Background>
        </ErrorBoundary>
      ) : renderNotFoundPage()}
    </ThemeProvider>
  );
};

export default App;
