import GridItem from 'components/Grid/GridItem';
import PagesRow from 'components/Grid/PagesRow';
import TaggedVideosRow from 'components/Grid/VideoTagRow';
import RealtimeDocument from 'components/core/RealtimeDocument';
import EventCalendar from 'components/page-blocks/EventCalendar';
import FAQ from 'components/page-blocks/FAQ';
import HeroBanner from 'components/page-blocks/HeroBanner';
import ImageAndText from 'components/page-blocks/ImageAndText';
import ImageGallery from 'components/page-blocks/ImageGallery';
import RichTextBlock from 'components/page-blocks/RichText';
import EmbedCode from 'components/page-blocks/EmbedCode';
import ShopifyBlock from 'components/page-blocks/Shopify';
import TextBanner from 'components/page-blocks/TextBanner';
import VideoSpotlight from 'components/page-blocks/VideoSpotlight';
import React from 'react';
import { useSelector } from 'react-redux';
import { getPendingOrActualLandingPageContent } from 'services/admin/selectors';
import { getPageId } from 'services/app';
import {
  IEventCalendarBlock,
  IFAQBlock,
  IHeroBannerBlock,
  IImageAndTextBlock,
  IImageGalleryBlock,
  ILandingPageContent,
  ILandingPageContentBlockKinds,
  IPagesRowBlock,
  IRichTextBlock,
  IShopifyBlock,
  ITaggedVideosBlock,
  ITextBannerBlock,
  IVideoSpotlightBlock,
  IEmbedBlock,
} from 'services/app/models/ILandingPageContent';

interface GridItemV2Props {
  index: number;
  item: ILandingPageContent;
  onGridContentEdit?: (kind: ILandingPageContentBlockKinds, index: number) => void;
  onPageBlockEdit?: <T> (data: T) => void;
  onPagesRowEdit?: (index: number, data: IPagesRowBlock) => void;
  onRemove?: (index: number) => void;
  onTaggedVideoEdit?: (block: ITaggedVideosBlock) => void;
}

const LandingContentItem: React.FC<GridItemV2Props> = ({
  index,
  item: contentItem,
  onGridContentEdit,
  onPageBlockEdit,
  onPagesRowEdit,
  onRemove,
  onTaggedVideoEdit,
}) => {
  const pageId = useSelector(getPageId);
  const landingPageContent = useSelector(getPendingOrActualLandingPageContent);

  const handlePageBlockEdit: <T>(edit: T) => void = React.useCallback((edit) => {
    onPageBlockEdit?.(edit);
  }, [onPageBlockEdit]);

  const handleGridContentEdit = React.useCallback(() => {
    onGridContentEdit?.(contentItem.kind, index);
  }, [onGridContentEdit, contentItem.kind, index]);

  const handlePagesRowEdit = React.useCallback((data: IPagesRowBlock) => {
    onPagesRowEdit?.(index, data);
  }, [onPagesRowEdit, index]);

  const handleRemove = React.useCallback(() => {
    onRemove?.(index);
  }, [onRemove, index]);

  const handleTaggedVideoEdit = React.useCallback(() => {
    onTaggedVideoEdit?.(contentItem as ITaggedVideosBlock);
  }, [onTaggedVideoEdit]);

  const renderPagesRow = (item: IPagesRowBlock) => {
    return (
      <PagesRow
        data={item}
        onEdit={handlePagesRowEdit}
        onRemove={handleRemove}
      />
    );
  };

  const renderImageGallery = (item: IImageGalleryBlock) => {
    return (
      <ImageGallery
        index={index}
        item={item}
        onEdit={handlePageBlockEdit}
        onRemove={handleRemove}
      />
    );
  };

  const renderHeroBanner = (item: IHeroBannerBlock) => {
    const nextKind = landingPageContent[index + 1]?.kind;
    const isNextBlockHeroBanner = nextKind === 'heroBanner';

    return (
      <HeroBanner
        disableImageBleed={isNextBlockHeroBanner}
        index={index}
        item={item}
        onEdit={handlePageBlockEdit}
        onRemove={handleRemove}
      />
    );
  };

  const renderTaggedVideos = (item: ITaggedVideosBlock) => {
    return (
      <TaggedVideosRow
        item={item}
        onEdit={handleTaggedVideoEdit}
        onRemove={handleRemove}
      />
    );
  };

  const renderTextBanner = (item: ITextBannerBlock) => {
    return (
      <TextBanner
        index={index}
        item={item}
        onEdit={handlePageBlockEdit}
        onRemove={handleRemove}
        pageId={pageId}
      />
    );
  };

  const renderFAQ = (item: IFAQBlock) => {
    return (
      <FAQ
        index={index}
        item={item}
        onEdit={handlePageBlockEdit}
        onRemove={handleRemove}
      />
    );
  };

  const renderImageAndText = (item: IImageAndTextBlock) => {
    return (
      <ImageAndText
        index={index}
        item={item}
        onEdit={handlePageBlockEdit}
        onRemove={handleRemove}
        pageId={pageId}
      />
    );
  };

  const renderVideoSpotlight = (item: IVideoSpotlightBlock) => {
    return (
      <VideoSpotlight
        index={index}
        item={item}
        onEdit={handlePageBlockEdit}
        onRemove={handleRemove}
      />
    );
  };

  const renderEventCalendar = (item: IEventCalendarBlock) => {
    return (
      <EventCalendar
        pageId={pageId}
        index={index}
        item={item}
        onEdit={handlePageBlockEdit}
        onRemove={handleRemove}
      />
    );
  };

  const renderRichText = (item: IRichTextBlock) => {
    return (
      <RichTextBlock
        index={index}
        item={item}
        onEdit={handlePageBlockEdit}
        onRemove={handleRemove}
        pageId={pageId}
      />
    );
  };

  const renderEmbedCode = (item: IEmbedBlock) => {
    return (
      <EmbedCode
        index={index}
        item={item}
        onEdit={handlePageBlockEdit}
        onRemove={handleRemove}
        pageId={pageId}
      />
    );
  };

  const renderShopify = (item: IShopifyBlock) => {
    return (
      <ShopifyBlock
        index={index}
        item={item}
        onEdit={handlePageBlockEdit}
        onRemove={handleRemove}
        region="landingPageBlock"
      />
    );
  };

  const renderGridItem = (doc: any, loaded: boolean | undefined) => {
    if (!doc || !loaded) {
      return null;
    }

    const lastInGrid = (index + 1) === landingPageContent.length;
    const repeatVideo = landingPageContent[index].kind === 'video' && landingPageContent[index + 1]?.kind === 'video';
    const showBorder = !lastInGrid && !repeatVideo;

    return (
      <GridItem
        doc={doc}
        onEdit={handleGridContentEdit}
        onRemove={handleRemove}
        showBorder={showBorder}
      />
    );
  };

  switch(contentItem.kind) {
    case 'heroBanner':
      return renderHeroBanner(contentItem);
    case 'pagesRow':
      return renderPagesRow(contentItem);
    case 'taggedVideos':
      return renderTaggedVideos(contentItem);
    case 'textBanner':
      return renderTextBanner(contentItem);
    case 'faqV1':
      return renderFAQ(contentItem);
    case 'imageGallery':
      return renderImageGallery(contentItem);
    case 'imageAndText':
      return renderImageAndText(contentItem);
    case 'videoSpotlight':
      return renderVideoSpotlight(contentItem);
    case 'eventCalendar':
      return renderEventCalendar(contentItem);
    case 'richText':
      return renderRichText(contentItem);
    case 'shopify':
      return renderShopify(contentItem);
    case 'embedCode':
      return renderEmbedCode(contentItem);
    default:
      return (
        <RealtimeDocument collection={contentItem.kind} id={contentItem[contentItem.kind]?._id}>
          {(doc, loaded) => {
            const node = renderGridItem(doc, loaded);
            if (!node) {
              return null;
            }
            return node;
          }}
        </RealtimeDocument>
      );
  }
};

export default LandingContentItem;
